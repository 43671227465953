exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutzrichtlinien-js": () => import("./../../../src/pages/datenschutzrichtlinien.js" /* webpackChunkName: "component---src-pages-datenschutzrichtlinien-js" */),
  "component---src-pages-haftungsausschluss-js": () => import("./../../../src/pages/haftungsausschluss.js" /* webpackChunkName: "component---src-pages-haftungsausschluss-js" */),
  "component---src-pages-hersteller-js": () => import("./../../../src/pages/hersteller.js" /* webpackChunkName: "component---src-pages-hersteller-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-zertifizierungen-js": () => import("./../../../src/pages/zertifizierungen.js" /* webpackChunkName: "component---src-pages-zertifizierungen-js" */)
}

